import React, { useContext } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

// import Header from "./components/header";
import Categories from "./components/categories";
import ContactForm from "../../components/contact-form";
import globalStyles from '../../components/global/global-style.module.scss';
import Title from '../../components/title'; 
import { saveAs } from 'file-saver';
import { ImagesContext } from "../../context/images-context";


const Products = () => {
  // const products_url = "https://shureprintcustomerscustomprinting.s3.us-east-2.amazonaws.com/files/Stock+Products+Guide.pdf";
  const products_url = "https://shureprintcustomerscustomprinting.s3.us-east-2.amazonaws.com/files/Stock+Products+Guide.pdf?fbclid=IwAR0yMSGE0OX3dePzbFvJjd52OFbMIaI1ICONFXgmyzU9Utwkh_vdDDNvZtY";
  const { text } = useContext(ImagesContext);

  const downloadFileAtUrl = async (pdfUrl) => {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();
    saveAs(blob, 'downloadedFile.pdf');
    console.log('error');
  };

  return (
    <section className={styles.products}>
      {/* <Header /> */}
      <div className={styles.header}>
        <div className={styles.text}>
          <div>
            <p>
              {text.find(item => item.text_name === "products_header_1")?.content}
            </p>
            <p>
              {text.find(item => item.text_name === "products_header_2")?.content}
            </p>
          </div>
        </div>
        <button
          onClick={() => downloadFileAtUrl(products_url)}
        >Download PDF</button>
      </div>
      <section className={`${styles.products__content}`}>
        <div className={styles.title}>
          <p>
          {text.find(item => item.text_name === "products_items_header_1")?.content + ' '}
            <span> 
             {text.find(item => item.text_name === "products_items_header_2")?.content}
            </span>
          </p>
        </div>
        <div className={styles.categories}>
          <Categories />
        </div>
        <div className={styles.contact}>
          <Title
              black={text.find(item => item.text_name === "products_contact_1")?.content}
              light={text.find(item => item.text_name === "products_contact_2")?.content}
          />
          <ContactForm />
        </div>
      </section>
    </section>
  );
};

export default Products;
