import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import fetchGraphQL from "../../../../helpers/sendRequest";
import { Link } from "react-router-dom";
import { CollectionsContext } from "../../../../context/collections-context";

export default function Categories() {
  const { bigCommerceProducts } = useContext(CollectionsContext);
  const renderCollections = () => {
    return bigCommerceProducts.map(({ node }) => (
      <Link
        to={`/products/${node.handle}`}
        className={styles.card}
        key={node.id}
      >
        <div className={styles.card__image}>
          <img src={node?.images?.edges[0]?.node?.url} alt="shure print" />
        </div>
        <h3 className={styles.card__title}>{node.name}</h3>
      </Link>
    ));
  };
  if (!bigCommerceProducts) return <div>Loading...</div>;
  if (!bigCommerceProducts.length) return <div>No Collections</div>;
  return <div className={`${styles.items}`}>{renderCollections()}</div>;
}
