import { useContext } from 'react';
import styles from './style.module.css';
import { ThemeContext } from '../../context/theme-context';
import Title from '../../components/title';
import ContactForm from '../../components/contact-form';
import { ImagesContext } from '../../context/images-context';

function Contact() {
    const { colors } = useContext(ThemeContext);
    const { text } = useContext(ImagesContext);

    return (
        <div className={styles.about}>
            <div className={styles.header}>
                <p>
                    {text.find(item => item.text_name === "contact_us_header")?.content}
                </p>
            </div>
            <Title
                black={text.find(item => item.text_name === "contact_us_1")?.content}
                light={text.find(item => item.text_name === "contact_us_2")?.content}
            />
            <ContactForm />
        </div>
    )
};

export default Contact;