export async function sendBigCommerceRequest(after, query) {
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOlsxNTcyNDkzXSwiY29ycyI6WyJodHRwOi8vbG9jYWxob3N0OjMwMDAiLCJodHRwczovL3NodXJlaHcuY29tIl0sImVhdCI6MTcyMTM5MTcwOSwiaWF0IjoxNzE4Nzk5NzA5LCJpc3MiOiJCQyIsInNpZCI6MTAwMzE3NDY3Nywic3ViIjoiNGE1YWhueGZjaXFlZ3pmcDZpeGhoMnpmeng1MndtciIsInN1Yl90eXBlIjoyLCJ0b2tlbl90eXBlIjoxfQ.P7zU0gEaGacIP4TdnLnYcQu5aycDEYxRVqbi3b2obLGbfkWomHNfwh5wLZQMfQohSpCdUEIryil4LjB3gMP7Pg";
  let params = {
    store_url: "https://store-lsgscaxueg-1572493.mybigcommerce.com/",
    token,
  };

  const storeUrl = new URL(params.store_url);

  // Use the store's canonical URL which should always resolve
  const graphQLUrl = `${storeUrl.origin}/graphql`;

  // Set up GraphQL query
  const graphQLQuery =
    query ||
    `
            query productListing {
                site {
                    products (first: 50 ${
                      after ? ', after:"' + after + '"' : ""
                    }) {
                        pageInfo {
                            startCursor
                            endCursor
                            }
                        edges {
                            cursor
                            node {
                                id
                                entityId
                                name
                                sku
                                description
                                prices {
                                    price {
                                        value
                                        currencyCode
                                    }
                                }
                                images {
                                    edges {
                                        node {
                                            url(width: 400)
                                        }
                                    }
                                  }
                            }
                        }
                    }
                }
            }`;

  // Fetch data from the GraphQL Storefront API
  const response = await fetch(graphQLUrl, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${params.token}`,
    },
    body: JSON.stringify({ query: graphQLQuery }),
  });
  const result = await response.json();
  const data = result.data;
  return data;
}

// Set up default params
