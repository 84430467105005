import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import { ThemeContextProvider } from "./context/theme-context";
// import Products from "./pages/products";
import CustomProducts from "./pages/custom";
import About from "./pages/about";
import Contact from "./pages/contact";
import WhoWeServe from "./pages/who-we-serve";
import CollectionContextProvider from "./context/collections-context";
import Category from "./pages/category";
import ServicesAndProducts from "./pages/services-products";
import Products from "./pages/categories";
import CollectionPage from "./pages/collection";
import ImagesContextProvider from "./context/images-context";

function App() {
  return (
    <div className="App">
      <ThemeContextProvider>
        <ImagesContextProvider>
          <CollectionContextProvider>
            <BrowserRouter>
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products" element={<Products />} />
                {/* <Route
                  path="/products/:collectionId"
                  element={<CollectionPage />}
                /> */}
                <Route
                  path="/services&products"
                  element={<ServicesAndProducts />}
                />
                <Route path="/custom" element={<CustomProducts />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/who-we-serve" element={<WhoWeServe />} />
                <Route path="/products/:id" element={<Category />} />
              </Routes>
              <Footer />
            </BrowserRouter>
          </CollectionContextProvider>
        </ImagesContextProvider>
      </ThemeContextProvider>
    </div>
  );
}

export default App;
