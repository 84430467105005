import { useContext, useEffect } from "react";
import ContactForm from "../../components/contact-form";
import styles from './style.module.css';
import { ThemeContext } from "../../context/theme-context";
import img1 from '../../assets/images/checkout.svg';
import img2 from '../../assets/images/person.svg';
import img3 from '../../assets/images/Sourcing.png';
import img4 from '../../assets/images/warehousing and fulfillment.svg';
import img5 from '../../assets/images/iconamoon_delivery-fast-thin.svg';
import Title from "../../components/title";
// import headerImage from "../../assets/images/main_header.jpg";
import { useNavigate } from "react-router-dom";
import { ImagesContext } from "../../context/images-context";


const items = [
    {
        image: img1,
        title: 'Inventory Management',
    },
    {
        image: img2,
        title: 'Designated Sales Representative',
    },
    {
        image: img3,
        title: 'Sourcing',
    },
    {
        image: img4,
        title: 'Warehousing + Fulfillment',
    },
    {
        image: img5,
        title: 'Delivery (5 days a week)',
    },
]

function Home() {
    const { colors } = useContext(ThemeContext);
    const navigate = useNavigate();
    const { text, images } = useContext(ImagesContext);
    const headerImg = images.filter((item) => item.shared_name === "home_header");

    return (
        <div className={styles.home}>
            <div className={styles.heading}
                style={{
                    backgroundImage:`url(${headerImg[0]?.photo_url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                {/* <div className={styles.bgImage}>
                    <
                </div> */}
                <div className={styles.text}>
                    <p className={styles.title}>
                        {text.find((item) => item.text_name === "header_text_1")?.content}
                    </p>
                    <p className={styles.description}>
                        {text.find((item) => item.text_name === "header_text_2")?.content}
                    </p>
                </div>
                <div className={styles.button}>
                    <button onClick={()=> navigate('/products')}>Get Started</button>
                </div>
            </div>
            <div className={styles.cards}>
                {items.map((item, index) => {
                    return(
                        <div className={styles.card}
                            style={{
                                background: colors.CaliSand,
                            }}
                            key={index}
                        >
                            <div className={styles.image}>
                                <img src={item.image} />
                            </div>
                            <div className={styles.title}>
                                <p>{item.title}</p>
                            </div>
                        </div>    

                    )
                })}
            </div>
            <Title 
                black={text.find((item) => item.text_name === "home_contact_1")?.content}
                light={text.find((item) => item.text_name === "home_contact_2")?.content}
            />
            <ContactForm />
        </div>
    )
};

export default Home;