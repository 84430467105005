import React from "react";
import Styles from "./style.module.scss";
import globalStyles from "../global/global-style.module.scss";

function ContactForm() {
    return (
        <section className={Styles.contact}>
            <form>
                <div className={Styles.content}>
                    <div className={Styles.inputList}>
                        <div className={Styles.col}>
                            <div className={Styles.inputItem}>
                                <label className={Styles.title}>Name*</label>
                                <input type="text" placeholder="your name" className={Styles.input} />
                            </div>
                            <div className={Styles.inputItem}>
                                <label className={Styles.title}>Email*</label>
                                <input type="email" placeholder="email" className={Styles.input} />
                            </div>
                        </div>
                        <div className={Styles.col}>
                            <div className={Styles.inputItem}>
                                <label className={Styles.title}>Phone*</label>
                                <input type="telphone" placeholder="Phone" className={Styles.input} />
                            </div>
                            <div className={Styles.inputItem}>
                                <label className={Styles.title}>Subject*</label>
                                <input type="text" placeholder="Subject" className={Styles.input} />
                            </div>
            
                        </div>
                    </div>
                    <div className={`${Styles.inputItem} ${Styles.TxtArea}` }>
                        <label className={Styles.title}>Message</label>
                        <textarea placeholder="Message" className={`${Styles.input}`} />
                    </div>
                    <div className={Styles.Send}>
                            <button>Send</button>
                    </div>
                </div>
            </form>
        </section>
    );
}

export default ContactForm;