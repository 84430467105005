import { useContext, useEffect, useState } from "react";
import ContactForm from "../../components/contact-form";
import Title from "../../components/title";
import styles from './style.module.css';
import Frame1 from "./components/frame1";
import Frame2 from "./components/frame2";
import { ImagesContext } from "../../context/images-context";

function ServicesAndProducts() {    
    const { text } = useContext(ImagesContext);

    return (
        <div className={styles.products}>
            <div className={styles.header}>
                <p>
                    {text.find((item) => item.text_name === "services_products_header_1")?.content}
                </p>
                <p>
                    {text.find((item) => item.text_name === "services_products_header_2")?.content}
                </p>
            </div>
            <div className={styles.content}>
                <Frame1 />            
                <Frame2 />            
            </div>
            <Title
                black={text.find((item) => item.text_name === "services_products_contact_1")?.content}
                light={text.find((item) => item.text_name === "services_products_contact_2")?.content}
            />
            <ContactForm />
        </div>
    );
}

export default ServicesAndProducts;