import styles from './style.module.scss';

function Title({black, light}) {
    return (
        <div className={styles.contactHeader}>
            <p>{ black }</p>
            <span> { light }</span>
        </div>
    );
};
export default Title;