import { createContext, useState } from "react";

const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
    const [colors, setColors] = useState({
        CaliSand: '#FFF9F0',

    })

    return (
        <ThemeContext.Provider value={{colors}}>
            {children}
        </ThemeContext.Provider>
    )
};

export { ThemeContext, ThemeContextProvider };

