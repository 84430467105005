import { useContext } from 'react';
import styles from './style.module.css';
import { ThemeContext } from '../../context/theme-context';
import Title from '../../components/title';
import ContactForm from '../../components/contact-form';
import { useNavigate } from 'react-router-dom';
import img1 from '../../assets/images/ShurePrint_20240214_IMG_8243.jpg';
import img2 from '../../assets/images/ShurePrint_20240214_IMG_8295.jpg';
import img3 from '../../assets/images/ShurePrint_20240214_IMG_8344.jpg';
import img4 from '../../assets/images/ShurePrint_20240214_IMG_8210.jpg';
import img5 from '../../assets/images/ShurePrint_20240214_IMG_8288.jpg';
import img6 from '../../assets/images/ShurePrint_20240214_IMG_8146.jpg';
import { ImagesContext } from '../../context/images-context';



function CustomProducts() {
    const { colors } = useContext(ThemeContext);
    const navigate = useNavigate();
    const { text } = useContext(ImagesContext);

    return (
        <div className={styles.customProducts}>
            <div className={styles.header}>
                <p>
                    {text.find(item => item.text_name === "custom_products_header1")?.content}
                </p>
                <p>
                    {text.find(item => item.text_name === "custom_products_header2")?.content}
                </p>
            </div>
            <div className={styles.visit}
                style={{
                    backgroundColor: colors.CaliSand
                }}
            >
                <p>
                    {text.find(item => item.text_name === "custom_products_content_title")?.content}
                </p>
                <a href='https://new-shure-print.vercel.app/'>
                    <button className={`${styles.button}`}>Go Now</button>
                </a>
                <div className={styles.items}>
                    <div className={styles.item}
                        style={{
                            backgroundImage: `url(${img1})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                    <div className={styles.item}
                        style={{
                            backgroundImage: `url(${img2})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                    <div className={styles.item}
                        style={{
                            backgroundImage: `url(${img3})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                    <div className={styles.item}
                        style={{
                            backgroundImage: `url(${img4})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                    <div className={styles.item}
                        style={{
                            backgroundImage: `url(${img5})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                    <div className={styles.item}
                        style={{
                            backgroundImage: `url(${img6})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                </div>
            </div>
            <Title 
                black={text.find(item => item.text_name === "custom_products_contact_1")?.content}
                light={text.find(item => item.text_name === "custom_products_contact_2")?.content}
            />
            <ContactForm />
        </div>
    )
};

export default CustomProducts;