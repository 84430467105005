import { createStorefrontClient } from "@shopify/hydrogen-react";
import { keys } from "../keys/keys";
export const client = createStorefrontClient({
  storeDomain: "https://shureprint.myshopify.com",
  storefrontApiVersion: "2023-01",
  privateStorefrontToken: keys.privateStorefrontToken,
});

const sendShopifyGraphQLQuery = async (query, variables) => {
  // Get the Storefront API url
  try {
    const response = await fetch(client.getStorefrontApiUrl(), {
      body: JSON.stringify({
        query,
        variables,
      }),
      // Generate the headers using the private token. Additionally, you can pass in the buyer's IP address from the request object to help prevent bad actors from overloading your store.
      headers: client.getPrivateTokenHeaders({
        buyerIp: "...",
        privateStorefrontToken: keys.privateStorefrontToken,
      }),
      method: "POST",
    });

    if (!response.ok) {
      console.log(response.statusText, "from status text");
    }
    const json = await response.json();
    console.log(json, "from json");
    return json;
  } catch (err) {
    console.log(err.message, "from error");
    throw err;
  }
};

export default sendShopifyGraphQLQuery;

const productsQuery = ``;
const excuteQuery = async (query, variables) => {
  const json = await sendShopifyGraphQLQuery(query, variables);
  console.log(json, "from  test Query");
};

excuteQuery(productsQuery);
