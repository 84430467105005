import React, { createContext, useEffect, useState } from "react";
import { createCart } from "../helpers/cart/createCart";
import { getCartDetails } from "../helpers/cart/loadCart";
import sendShopifyGraphQLQuery from "../helpers/sendRequest";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabase";

export const ImagesContext = createContext(null);
const ImagesContextProvider = (props) => {
  const [images, setImages] = useState([]);
  const [text, setText] = useState([]);
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from("photos_content")
        .select()
        .eq("webiste_name", "shurehs");
      const { data: text, error: textError } = await supabase
        .from("website_text")
        .select()
        .eq("website_name", "shurehs");
      setText(text);
      setImages(data);
    };
    fetchData();
  }, []);
  console.log(images, "from images");
  const contextValue = {
    images,
    text,
  };

  return (
    <ImagesContext.Provider value={contextValue}>
      {props.children}
    </ImagesContext.Provider>
  );
};

export default ImagesContextProvider;
