import { useContext, useState } from 'react';
import styles from './style.module.css';
import { ThemeContext } from '../../context/theme-context';
import Title from '../../components/title';
import ContactForm from '../../components/contact-form';
import img1 from '../../assets/images/serve1.png';
import img2 from '../../assets/images/serve2.png';
import img3 from '../../assets/images/serve3.jpg';
import img4 from '../../assets/images/serve4.jpg';
import img5 from '../../assets/images/serve5.png';
import img6 from '../../assets/images/serve6.png';
import { ImagesContext } from '../../context/images-context';


function WhoWeServe() {
    const { colors } = useContext(ThemeContext);
    const { text, images } = useContext(ImagesContext);
    const serveItems = images.filter((item) => item.shared_name === "who_we_serve");
    const renderItems = serveItems.map((item, index) => {
        return (
            <div className={styles.item} key={index}
                style={{
                    backgroundImage: `url(${item?.photo_url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className={styles.itemTitle}>
                    <p>{item?.photo_name}</p>
                </div>
            </div>
        );
    })

    return (
        <div className={styles.customProducts}>
            <div className={styles.header}>
                <p>Who We Serve</p>
                <p>We are here to serve industry experts like you!</p>
            </div>
            <div className={styles.items}
                style={{
                    backgroundColor: colors.CaliSand
                }}
            >
                {renderItems}
            </div>
            <Title 
                black={text.find(item => item.text_name === "who_we_serve_contact_1")?.content}
                light={text.find(item => item.text_name === "who_we_serve_contact_2")?.content}
            />
            <ContactForm />
        </div>
    )
};

export default WhoWeServe;