import styles from './style.module.css';
import globalStyles from '../../../../components/global/global-style.module.scss';
import { ThemeContext, useTheme } from '../../../../context/theme-context';
import { useContext } from 'react';
import img1 from '../../../../assets/images/clarity_design-line.svg';
import img2 from '../../../../assets/images/fontisto_sourcetree.svg';
import img3 from '../../../../assets/images/Vector.svg';
import img4 from '../../../../assets/images/entypo_tools.svg';
import { ImagesContext } from '../../../../context/images-context';

const Frame2 = () => {
    const {colors} = useContext(ThemeContext)
    const { text } = useContext(ImagesContext);
    
    return (
        <div className={styles.frame}>
            <div className={styles.title}>
                <p>
                    {text.find((item) => item.text_name === "services_products_section2_header")?.content}
                </p>
            </div>
            <div className={styles.container}>
                <div className={styles.item}>
                    <div className={styles.itemTitle}
                        style={{
                            backgroundColor: colors.CaliSand,
                        }}
                    >
                        <p>
                            {text.find((item) => item.text_name === "services_products_section2_item1_title")?.content}
                        </p>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.avatar}>
                            <img src={img1} alt="" />
                        </div>
                        <div className={styles.text}>
                            <p>
                                {text.find((item) => item.text_name === "services_products_section2_item1_text")?.content}    
                            </p>
                        </div>
                    </div>
                    <div className={styles.divider}>
                        <hr></hr>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.itemTitle}
                        style={{
                            backgroundColor: colors.CaliSand,
                        }}
                    >
                        <p>
                            {text.find((item) => item.text_name === "services_products_section2_item2_title")?.content}
                        </p>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.avatar}>
                            <img src={img2} alt="" />
                        </div>
                        <div className={styles.text}>
                            <p>
                                {text.find((item) => item.text_name === "services_products_section2_item2_text")?.content}
                            </p>    
                        </div>
                    </div>
                    <div className={styles.divider}>
                        <hr></hr>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.itemTitle}
                        style={{
                            backgroundColor: colors.CaliSand,
                        }}
                    >
                        <p>
                            {text.find((item) => item.text_name === "services_products_section2_item3_title")?.content}
                        </p>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.avatar}>
                            <img src={img3} alt="" />
                        </div>
                        <div className={styles.text}>
                            <p>
                                {text.find((item) => item.text_name === "services_products_section2_item3_text")?.content}
                            </p>    
                        </div>
                    </div>
                    <div className={styles.divider}>
                        <hr></hr>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.itemTitle}
                        style={{
                            backgroundColor: colors.CaliSand,
                        }}
                    >
                        <p>
                            {text.find((item) => item.text_name === "services_products_section2_item4_title")?.content}
                        </p>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.avatar}>
                            <img src={img4} alt="" />
                        </div>
                        <div className={styles.text}>
                            <p>
                                {text.find((item) => item.text_name === "services_products_section2_item4_text")?.content}
                            </p>    
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Frame2;