let privateStorefrontToken = process.env.REACT_APP_PRIVATE_STORE_FRONT_TOKEN;
let publicStorefrontToken = process.env.REACT_APP_PUBLIC_SOTRE_FRONT_TOKEN;
let AWSSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
let AWSAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY;
let emailyUserId = process.env.REACT_APP_EMAILY_USER_ID;
let emailyTemplateId = process.env.REACT_APP_EMAILY_TEMPLATE_ID;
let emailyServiceId = process.env.REACT_APP_EMAILY_SERVICE_ID;
let supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
let supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
if (process.env.NODE_ENV === "development") {
  const privateKeys = require("./privateKeys");
  privateStorefrontToken = privateKeys.privateStorefrontToken;
  publicStorefrontToken = privateKeys.publicStorefrontToken;
  AWSSecretAccessKey = privateKeys.AWSSecretAccessKey;
  AWSAccessKeyId = privateKeys.AWSAccessKeyId;
  emailyTemplateId = privateKeys.emailyTemplateId;
  emailyUserId = privateKeys.emailyUserId;
  emailyServiceId = privateKeys.emailyServiceId;
  supabaseKey = privateKeys.supabaseKey;
  supabaseUrl = privateKeys.supabaseUrl;
}

export const keys = {
  privateStorefrontToken,
  publicStorefrontToken,
  AWSSecretAccessKey,
  AWSAccessKeyId,
  emailyTemplateId,
  emailyUserId,
  emailyServiceId,
  supabaseKey,
  supabaseUrl,
};