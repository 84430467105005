import { useParams } from "react-router-dom";
import styles from "./styles.module.css";

const Category = () => {
  const { id } = useParams();

  return <div>Category , from category </div>;
};

export default Category;
