import { useContext } from 'react';
import styles from './style.module.css';
import { ThemeContext } from '../../context/theme-context';
import Title from '../../components/title';
import ContactForm from '../../components/contact-form';
import { ImagesContext } from '../../context/images-context';

function About() {
    const { colors } = useContext(ThemeContext);
    const { text } = useContext(ImagesContext);

    return (
        <div className={styles.about}>
            <div className={styles.header}>
                <p>
                    {text.find(item => item.text_name === "about_us_header_1")?.content}
                </p>
                <p>
                    {text.find(item => item.text_name === "about_us_header_2")?.content}
                </p>
            </div>
            <div className={styles.content}
                style={{
                    backgroundColor: colors.CaliSand
                }}
            >
                <p>
                    {text.find(item => item.text_name === "about_us_1")?.content}
                </p>
                <p>
                    {text.find(item => item.text_name === "about_us_2")?.content}
                </p>
                <p>
                    {text.find(item => item.text_name === "about_us_3")?.content}
                </p>
                <p>
                    {text.find(item => item.text_name === "about_us_4")?.content}
                </p>
                <p>
                    {text.find(item => item.text_name === "about_us_5")?.content}
                </p>
            </div>
        </div>
    )
};

export default About;