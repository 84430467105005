import React, { useState, useEffect, useContext } from "react";
import styles from "./style.module.scss";
import globalStyles from "../global/global-style.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/SHW Logo.svg";
import shoopingCart from "../../assets/images/shopping-cart.svg";
import { ThemeContext } from "../../context/theme-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import menu from "../../assets/images/menu.svg";
import close from "../../assets/images/close.svg";
import arrowRight from "../../assets/images/long-arrow-alt-right.png";

const Navbar = () => {
  const { colors } = useContext(ThemeContext);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [selectedLink, setSelectedLink] = useState("home");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setSelectedLink(pathname);
  }, [pathname]);

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(colors, "from navbar");
  }, []);

  const handleMobLinkClick = (link) => {
    // if (link === '') {
    //   setSelectedLink('home');

    // } else {
    //   setSelectedLink(link);

    // }

    navigate(`/${link}`);
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <>
      <nav className={`${styles.navbarMobile}`}>
        <div className={styles.announce}>
          <p>Built for Great Brands</p>
        </div>
        <div
          className={styles.middle}
          style={{
            background: colors.CaliSand,
          }}
        >
          <div
            className={styles.toggle}
            onClick={() => setIsNavExpanded(!isNavExpanded)}
          >
            <img src={menu} alt="menu" />
          </div>
          <div className={styles.logo}>
            <img src={logo} alt="shureprint" />
          </div>
          <div
            className={isNavExpanded ? styles.close : styles.none}
            onClick={() => setIsNavExpanded(false)}
          >
            <img src={close} alt="close" />
          </div>
          <Link
            to="/Cart"
            className={
              isNavExpanded
                ? `${styles.none} ${styles.Cart}`
                : ` ${styles.Cart}`
            }
          >
            <img src={shoopingCart} className={`${styles.ShoppingCart}`} />
            <p className={styles.Count}>0</p>
            <span>Cart</span>
          </Link>
        </div>
        <div className={styles.header}>
          <div
            className={
              isNavExpanded ? styles.menu : `${styles.menu} ${styles.closeMenu}`
            }
          >
            <ul
              className={
                isNavExpanded
                  ? `${styles.links} ${styles.show}`
                  : `${styles.links}`
              }
            >
              <li
                className={
                  selectedLink === "home" ? styles.selected : styles.link
                }
                onClick={() => {
                  handleMobLinkClick("");
                }}
              >
                <p>Home</p>
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.81005 2.65625V1.39844C8.81005 0.824219 9.52099 0.523438 9.93114 0.933594L12.2827 3.3125C12.5561 3.55859 12.5561 3.96875 12.2827 4.21484L9.93114 6.59375C9.52099 7.00391 8.81005 6.70312 8.81005 6.12891V4.84375H0.579579C0.388173 4.84375 0.251454 4.70703 0.251454 4.51562V2.98438C0.251454 2.82031 0.388173 2.65625 0.579579 2.65625H8.81005Z" />
                </svg>
              </li>
              <li
                className={
                  selectedLink === "collections" ? styles.selected : styles.link
                }
                onClick={() => {
                  handleMobLinkClick("services&products");
                }}
              >
                <p>Services + Products</p>
                {/* <img src={arrowRight} alt="arrow" /> */}
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.81005 2.65625V1.39844C8.81005 0.824219 9.52099 0.523438 9.93114 0.933594L12.2827 3.3125C12.5561 3.55859 12.5561 3.96875 12.2827 4.21484L9.93114 6.59375C9.52099 7.00391 8.81005 6.70312 8.81005 6.12891V4.84375H0.579579C0.388173 4.84375 0.251454 4.70703 0.251454 4.51562V2.98438C0.251454 2.82031 0.388173 2.65625 0.579579 2.65625H8.81005Z" />
                </svg>
              </li>
              <li
                className={
                  selectedLink === "packages" ? styles.selected : styles.link
                }
                onClick={() => {
                  handleMobLinkClick("custom");
                }}
              >
                <p>Custom Products</p>
                {/* <img src={arrowRight} alt="arrow" /> */}
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.81005 2.65625V1.39844C8.81005 0.824219 9.52099 0.523438 9.93114 0.933594L12.2827 3.3125C12.5561 3.55859 12.5561 3.96875 12.2827 4.21484L9.93114 6.59375C9.52099 7.00391 8.81005 6.70312 8.81005 6.12891V4.84375H0.579579C0.388173 4.84375 0.251454 4.70703 0.251454 4.51562V2.98438C0.251454 2.82031 0.388173 2.65625 0.579579 2.65625H8.81005Z" />
                </svg>
              </li>
              <li
                className={
                  selectedLink === "Wholesale" ? styles.selected : styles.link
                }
                onClick={() => {
                  handleMobLinkClick("who-we-serve");
                }}
              >
                <p>Who We Serve</p>
                {/* <img src={arrowRight} alt="arrow" /> */}
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.81005 2.65625V1.39844C8.81005 0.824219 9.52099 0.523438 9.93114 0.933594L12.2827 3.3125C12.5561 3.55859 12.5561 3.96875 12.2827 4.21484L9.93114 6.59375C9.52099 7.00391 8.81005 6.70312 8.81005 6.12891V4.84375H0.579579C0.388173 4.84375 0.251454 4.70703 0.251454 4.51562V2.98438C0.251454 2.82031 0.388173 2.65625 0.579579 2.65625H8.81005Z" />
                </svg>
              </li>
              <li
                className={
                  selectedLink === "about-us" ? styles.selected : styles.link
                }
                onClick={() => {
                  handleMobLinkClick("about");
                }}
              >
                <p>About Us</p>
                {/* <img src={arrowRight} alt="arrow" /> */}
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.81005 2.65625V1.39844C8.81005 0.824219 9.52099 0.523438 9.93114 0.933594L12.2827 3.3125C12.5561 3.55859 12.5561 3.96875 12.2827 4.21484L9.93114 6.59375C9.52099 7.00391 8.81005 6.70312 8.81005 6.12891V4.84375H0.579579C0.388173 4.84375 0.251454 4.70703 0.251454 4.51562V2.98438C0.251454 2.82031 0.388173 2.65625 0.579579 2.65625H8.81005Z" />
                </svg>
              </li>
              <li
                className={
                  selectedLink === "contact-us" ? styles.selected : styles.link
                }
                onClick={() => {
                  handleMobLinkClick("contact");
                }}
              >
                <p>Contact Us</p>
                {/* <img src={arrowRight} alt="arrow" /> */}
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.81005 2.65625V1.39844C8.81005 0.824219 9.52099 0.523438 9.93114 0.933594L12.2827 3.3125C12.5561 3.55859 12.5561 3.96875 12.2827 4.21484L9.93114 6.59375C9.52099 7.00391 8.81005 6.70312 8.81005 6.12891V4.84375H0.579579C0.388173 4.84375 0.251454 4.70703 0.251454 4.51562V2.98438C0.251454 2.82031 0.388173 2.65625 0.579579 2.65625H8.81005Z" />
                </svg>
              </li>
            </ul>
            <div className={styles.buttons}>
              <button className={`${styles.Signup} ${styles.button}`}>
                <a
                  href="https://store-lsgscaxueg-1572493.mybigcommerce.com/?ctk=914eb5fe-6a47-494d-8aea-d8eadbc70b11#/login"
                  target="_blank"
                >
                  {" "}
                  Sign Up
                </a>
              </button>
              <button className={`${styles.Login} ${styles.button}`}>
                <a
                  href="https://store-lsgscaxueg-1572493.mybigcommerce.com/?ctk=914eb5fe-6a47-494d-8aea-d8eadbc70b11#/register"
                  target="_blank"
                >
                  Login
                </a>
              </button>
            </div>
          </div>
        </div>
      </nav>
      <nav className={`${styles.navbar}`}>
        <div className={styles.announce}>
          <p>Built for Great Brands</p>
        </div>
        <div
          className={styles.header}
          style={{
            background: colors.CaliSand,
          }}
        >
          <div
            className={styles.logo}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={logo} alt="shureprint" />
          </div>
          <div className={styles.menu}>
            <div className={styles.register}>
              <div className={styles.buttons}>
                <button className={`${styles.Signup} ${styles.button}`}>
                  <a
                    href="https://store-lsgscaxueg-1572493.mybigcommerce.com/?ctk=914eb5fe-6a47-494d-8aea-d8eadbc70b11#/login"
                    target="_blank"
                  >
                    {" "}
                    Sign Up
                  </a>
                </button>
                <button className={`${styles.Login} ${styles.button}`}>
                  <a
                    href="https://store-lsgscaxueg-1572493.mybigcommerce.com/?ctk=914eb5fe-6a47-494d-8aea-d8eadbc70b11#/register"
                    target="_blank"
                  >
                    Login
                  </a>
                </button>
              </div>
              <Link
                to="/Cart"
                className={
                  isNavExpanded
                    ? `${styles.none} ${styles.Cart}`
                    : ` ${styles.Cart}`
                }
              >
                <img src={shoopingCart} className={`${styles.ShoppingCart}`} />

                <p className={styles.Count}>0</p>
                <span>Cart</span>
              </Link>
            </div>
            <ul
              className={
                isNavExpanded
                  ? `${styles.links} ${styles.show}`
                  : `${styles.links}`
              }
            >
              <li>
                <Link
                  to="/"
                  className={
                    selectedLink === "/" ? styles.selected : styles.link
                  }
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/services&products"
                  className={
                    selectedLink === "/services&products" ||
                    selectedLink.includes("/products")
                      ? styles.selected
                      : styles.link
                  }
                >
                  Services + Products
                </Link>
              </li>
              <li>
                <Link
                  to="/custom"
                  className={
                    selectedLink === "/custom" ? styles.selected : styles.link
                  }
                >
                  Custom Products
                </Link>
              </li>
              <li>
                <Link
                  to="/who-we-serve"
                  className={
                    selectedLink === "/who-we-serve"
                      ? styles.selected
                      : styles.link
                  }
                >
                  Who We Serve
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className={
                    selectedLink === "/about" ? styles.selected : styles.link
                  }
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className={
                    selectedLink === "/contact" ? styles.selected : styles.link
                  }
                >
                  Contact Us
                </Link>
              </li>
              <li className={`${styles.Btn} ${styles.link}`}>
                <a
                  href="https://store-lsgscaxueg-1572493.mybigcommerce.com/#/register"
                  target="_blank"
                  className={`${globalStyles.button2} ${styles.button}`}
                >
                  Sign Up
                </a>
                <a
                  href="https://store-lsgscaxueg-1572493.mybigcommerce.com/#/login"
                  className={`${globalStyles.button1} ${styles.button}`}
                >
                  Login
                </a>
              </li>
            </ul>
          </div>
          {/* <header className={`${styles.navbar__header} ${globalStyles.padding5}`}>
                        
                    </header> */}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
