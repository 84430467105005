import React, { useContext } from "react";
import styles from "./style.module.scss"
import globalStyles from "../global/global-style.module.scss";
import { ThemeContext } from "../../context/theme-context";
import instaImg from '../../assets/images/icons8-instagram 1.svg';

function Footer() {
    const { colors } = useContext(ThemeContext);

    return (
        <section className={`${styles.footer}`}
            style={{
                background:colors.CaliSand, 
            }}
        >
            <div className={styles.header}>
                <p>SHURE HOSPITALITY WHOLESALE</p>
            </div>
            <div className={styles.body}>
                <div className={`${styles.left}`}>
                    <div className={`${styles.connect}`}>
                        <p className={styles.title}>Connect</p>
                        <a href="#"><img src={instaImg} /></a>
                    </div>
                    <div className={`${styles.help}`}>
                        <p className={styles.title} >Help</p>
                        <a href="#" >Contact Us</a>
                        <a href="#" >FAQ</a>
                        <a href="#" >Privacy & Cookies</a>
                        <a href="#" >Terms & Conditions</a>
                        <a href="#" >
                            Do not sell my personal information
                        </a>
                    </div>
                </div>
                <div className={`${styles.right}`}>
                    <div className={styles.content}>
                        <p className={styles.title}>Unlock exclusive deals, tips, and more</p>
                        <form>    
                            <input type="email" placeholder="Enter Your Email here..." />
                        <div className={styles.Subscribe}>
                            <button>Subscribe</button>
                        </div>
                        </form>
                    </div>    
                </div>
            </div>
        </section>
    );
}

export default Footer;