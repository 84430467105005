import { createContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import sendShopifyGraphQLQuery from "../helpers/sendRequest";
import { sendBigCommerceRequest } from "../helpers/sendBigCommerceRequest";
const GET_COLLECTIONS_QUERY = `
{
  collections(first: 50) {
    edges {
      node {
        id
        handle
        title
        description

        image {
          originalSrc
        }
      }
    }
  }
}
`;

export const CollectionsContext = createContext(null);
const CollectionContextProvider = ({ children }) => {
  const [collections, setCollections] = useState(null);
  const [bigCommerceProducts, setBigCommerceProducts] = useState(null);

  const query = `
  query GetAllCategories {
    site {
      categoryTree {
        entityId
        name
        path
        children {
          entityId
          name
          path
          children {
            entityId
            name
            path
          }
        }
      }
    }
  }
  
  
  `;
  useEffect(() => {
    const getCollections = async () => {
      //const json = await sendShopifyGraphQLQuery(GET_COLLECTIONS_QUERY);
      const data = await sendBigCommerceRequest();
      console.log(data, "bigCommerce from data in context provider");
      setBigCommerceProducts(
        data.site.products.edges.filter(
          (item) => item?.node?.images?.edges[0]?.node.url
        )
      );
      let next = data?.site?.products?.pageInfo?.endCursor;
      for (let i = 0; i < 2; i++) {
        const secondData = await sendBigCommerceRequest(next);
        setBigCommerceProducts((products) => [
          ...products,
          ...secondData.site.products.edges.filter(
            (item) => item?.node?.images?.edges[0]?.node.url
          ),
        ]);
        next = secondData?.site?.products?.pageInfo?.endCursor;
      }
      console.log(bigCommerceProducts, "from bigCommerce products");

      //setCollections(json.data.collections.edges);
    };
    getCollections();
  }, []);
  console.log(collections, "from collections in context provider");
  const valueToShare = {
    collections,
    bigCommerceProducts,
  };
  return (
    <CollectionsContext.Provider value={valueToShare}>
      {children}
    </CollectionsContext.Provider>
  );
};

export default CollectionContextProvider;
