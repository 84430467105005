import styles from './style.module.css';
import globalStyles from '../../../../components/global/global-style.module.scss';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ImagesContext } from '../../../../context/images-context';

const Frame1 = () => {
    const navigate = useNavigate();
    const { text } = useContext(ImagesContext);

    return (
        <div className={styles.frame}>
            <div className={styles.title}>
            {text.find((item) => item.text_name === "services_products_section1_header")?.content}
            </div>
            <div className={styles.text}>
                <p>
                    {text.find((item) => item.text_name === "services_products_section1_text1")?.content}
                </p>
                <p>
                    {text.find((item) => item.text_name === "services_products_section1_text2")?.content}
                </p>
            </div>
            <div className={styles.button}>
                <button className={globalStyles.button1}
                    onClick={()=> navigate('/products')}
                >View all products</button>
            </div>
        </div>
    )
}

export default Frame1;